<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    icon
                    fab
                    :color="color"
                    @click="switchSummitSidebar()"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon :color="color" small>icon-q-summit-icon</v-icon>
                </v-btn>
            </template>
            <span>Summit</span>
        </v-tooltip>
    </div>
</template>

<script>
import { store } from './store/sidebar-store'

export default {
	props: {
		color: { type: String, default: '#005851' },
	},
    computed: {
        doShowDrawer: {
            get() { return store.doShowSummitSidebar },
            set(val) { store.doShowSummitSidebar = val }
        }
    },
    methods: {
        switchSummitSidebar() {
            this.doShowDrawer = !this.doShowDrawer

            if (this.doShowDrawer) {
                this.$amplitude.track('Q2A_SummitDrawer')
            }
        },
    },
}
</script>
