<template>
    <div>
        <v-menu offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on }">
                <v-btn :color="btnColor" text small fab v-on="on">
                    <v-avatar size="40" small v-if="doShowAvatar">
                        <v-img :src="user.avatar" :alt="user.name"></v-img>
                    </v-avatar>
                    <v-icon v-else>fas fa-user</v-icon>
                </v-btn>
            </template>

            <v-list style="min-width:250px;">
                <v-list-item>
                    <v-list-item-title class="pb-4">
                        Welcome<br /><b>{{ $auth.auth_user.name}}</b>!
                        <span v-if="isImpersonating"><em>Impersonating</em></span>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isImpersonating" @click="revertImpersonation">
                    <v-list-item-avatar>
                        <v-icon small>fas fa-undo-alt</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Switch Back</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name:'User Profile'}">
                    <v-list-item-avatar>
                        <v-icon small>fas fa-user</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>My Profile</v-list-item-title>
                </v-list-item>
                <v-list-item :to="'/user/account_balance'" v-if="user.Agent.AgentAccount">
                    <v-list-item-avatar>
                        <v-icon small>fas fa-dollar-sign</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Account Balance : {{ formatCurrency(user.Agent.AgentAccount.Balance) }}</v-list-item-title>
                </v-list-item>
                <v-list-item :to="'/user/account_balance'" v-else>
                    <v-list-item-avatar>
                        <v-icon small>fas fa-dollar-sign</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Add Credit Card</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{name:'My Exports'}">
                    <v-list-item-avatar>
                        <v-icon small>fas fa-file-csv</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>My Exports</v-list-item-title>
                </v-list-item>
                <v-divider v-if="raw_divisions.length > 1 "></v-divider>
                <v-item-group v-if="raw_divisions.length > 1 ">
                    <v-subheader>Divisions</v-subheader>
                    <v-list-item v-for="(d, key) in raw_divisions" @click="changeDivision(d)" :key="key">
                        <v-list-item-avatar>
                            <v-icon small>fas fa-chevron-right</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title>{{d}}</v-list-item-title>
                    </v-list-item>
                </v-item-group>
                <v-divider></v-divider>
                <v-list-item @click="logout">
                    <v-list-item-avatar>
                        <v-icon small>fas fa-sign-out-alt</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { EventBus } from '@/event-bus';
export default {
    name: "QUserMenu",

    props: {
        showAvatar: { type: Boolean, default: true },
		color: { type: String, default: '#005851' },
    },

	computed: {
		doShowAvatar () {
			return this.showAvatar && this.user.avatar
		},
		btnColor () {
			return this.doShowAvatar ? undefined : this.color
		},
	},

    methods: {
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin
            });
        },
        revertImpersonation() {
            QuilityAPI.revertImpersonation().then(function(json) {
                if (json.success == true) {
                    //redirect the admin back to the detail page for that agent. 
                    document.location.href = process.env.VUE_APP_BASE_URL + "/agents/" + json.AgentCode;
                }
            })
        },
        changeDivision(d) {
            EventBus.$emit('changeDivision', d)
        },
    },
}
</script>
