<template>
    <v-app-bar flat dark clipped-left class="q-app-bar" :class="{ 'q-app-bar--mobile': isMobile }" height="72px">
        <div class="q-app-bar__content">
            <div class="q-app-bar__left">
                <v-app-bar-nav-icon v-if="isTabletAndDown" :small="isMobile" color="#005851" @click.stop="toggleMobileMenu" />

                <QResourceMenuField id="v-step-1" v-if="hasDivision(['Field'])" />
                <QResourceMenuCorporate id="v-step-1" v-if="hasDivision(['Corporate'])" />
                <QResourceMenuCss id="v-step-1" v-if="hasCcsDivision" />
            </div>

            <v-spacer />
            <v-toolbar-title style="height: 100%">
                <router-link to="/" style="height: 100%; display: block">
                    <img class="nav-logo" :src="logoPath" alt="Quility Dash Board" contain height="100%" />
                </router-link>
            </v-toolbar-title>
            <v-spacer />

            <div v-if="hasRole(['SuperAdmin']) && user.currentSetDivision && (!isTabletAndDown)" class="overline" style="width: 1px;">
                <div style="text-align: right; float: right; width: 200px; opacity: .75;" class="pr-6">
                    {{ user.currentSetDivision }} DIVISION
                </div>
            </div>

            <div class="q-app-bar__right">
                <!-- Used for opening the right slider if we want it -->
				<!-- <v-btn small text fab @click.stop="toggleRightMenu">
					<v-icon>fas fa-th</v-icon>
				</v-btn> -->

				<v-tooltip v-if="!hasCcsDivision" bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn small depressed fab to="/perform-dash" class="app-bar-icon__perform-dash" :class="isMobile && 'app-bar-icon__perform-dash--mobile'" v-bind="attrs" v-on="on">
							<v-icon color="#005851">icon-perform-dash</v-icon>
						</v-btn>
					</template>
					<span>PerformDash</span>
				</v-tooltip>

                <QSummitOpenSidebarButton v-if="!hasCcsDivision" />
                <QSearch id="v-step-2" v-if="!isMobile" />
                <QNotificationMenu id="v-step-3" :class="!isMobile && 'q-app-bar__notification'" />
                <QUserMenu id="v-step-3a" :show-avatar="!isMobile" />

                <!---
				<div>
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
						<v-btn @click="startTour" icon v-bind="attrs" v-on="on">
							<i class="fas fa-2x fa-info-circle"></i>
						</v-btn>
						</template>
						<span>Quickly tour available functions </span>
					</v-tooltip>
				</div>
				-->
            </div>
        </div>
    </v-app-bar>
</template>

<script>
import QSearch from '../utils/QSearch';
import QUserMenu from './QUserMenu';
import QNotificationMenu from './QNotificationMenu';
import QResourceMenuCorporate from './QResourceMenu/QResourceMenuCorporate';
import QResourceMenuField from './QResourceMenu/QResourceMenuField';
import QResourceMenuCss from './QResourceMenu/QResourceMenuCss';
import QSummitOpenSidebarButton from '../summit/sidebar/QSummitOpenSidebarButton.vue';
import VerticalLogo from '@/assets/images/QuilityLogoVerticalBlackRGB.svg';
import HorizontalLogo from '@/assets/images/QuilityLogoHorizontalColor.svg';

export default {
    name: "QAppbar",
    props: ['icon', 'text', 'route', 'click'],

    computed: {
        isMobile() { return this.$vuetify.breakpoint.xs; },
        isTabletAndDown() { return this.$vuetify.breakpoint.smAndDown; },

        logoPath() {
            return this.isMobile
                ? VerticalLogo
                : HorizontalLogo
        },
        hasCcsDivision() {
            return this.hasDivision(['CCS'])
        },
    },

    methods: {
        startTour() {
            this.$refs.tour.startTour(this.$route.name);
        },
        toggleMobileMenu: function() {
            this.$emit('mobileMenu')
        },
        toggleRightMenu: function() {
            this.$emit('rightMenu')
        },
        clicked: function() {
            if (typeof this.route == 'string') {
                this.$router.push({ name: this.route }).catch(err => { this.showError("Whoops! " + err) })
            }
            if (typeof this.click == 'function') {
                this.click();
            }
        }
    },
    components: {
        QSearch,
        QUserMenu,
        QNotificationMenu,
        QResourceMenuField,
        QResourceMenuCorporate,
        QResourceMenuCss,
        QSummitOpenSidebarButton,
    }
}
</script>
<style lang="scss" scoped>
.v-tour__target--relative {
    margin-top: 0px !important;
}

.app-bar-icon__perform-dash {
	// border-radius: 50%;
	background-color: transparent !important;

	&.v-btn--active:before {
		opacity: 0 !important;
	}

	.icon-perform-dash::before {
		width: unset;
		font-size: 18px;
	}

	&--mobile {
		.icon-perform-dash::before {
			font-size: 16px;
		}
	}
}
</style>

<style lang="scss">
.q-app-bar {
	&.v-app-bar {
		color: var(--app-color-secondary) !important;
		background-color: var(--app-color-neutral-4) !important;
	}

	.v-toolbar__content {
		padding: 1rem 1rem;
	}

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__left,
    &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        &>* {
            margin: 0 4px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__notification {
        align-self: stretch;
    }

    &--mobile {
		.v-toolbar__content {
			padding: 0.5rem 0.5rem;
		}

        .q-app-bar {
            &__content {
                align-items: flex-end;
            }

            &__left,
            &__right {
                align-items: flex-end;
            }
        }

        .v-btn {
            height: 24px !important;
            width: 24px !important;
        }
    }

    .v-toolbar__content {
        padding-top: 0;
        padding-bottom: 0;
    }
}
</style>